module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-32HQCVPFK5"],"pluginConfig":{"head":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"SB Invest","short_name":"SBI","start_url":"/","background_color":"#ecedf2","theme_color":"#47a64a","display":"standalone","icon":"src/static/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"8c71c5d93e66d34ca1c74ad4c1141a6d"},
    },{
      plugin: require('../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/assets/typography/typogrphy.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-sentry/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://3d70dd98d47c4a8981fcb6bdf3a59b8c@test.sentry.adap.tw/26","environment":"staging"},
    }]
